var $text = $('body.search #content-section .content-wrapper .search-result a .post-info p');
var $heading = $('body.search #content-section .content-wrapper .search-result a .post-info h2');
var $searchPage = ('body.search');
var $inputValue = $('#content-section h1 span').text();

function highlightText($inputValue, $text) {
    var rgxp = new RegExp($inputValue, 'gi');
    var repl = '<span class="highlight">' + $inputValue + '</span>';
    var origText;
    $text.each(function (index, element) {
        var match = $(this).text().match(rgxp);
        if (match) {
            origText = match[0];
        }
        $(this).html($(this).html().replace(rgxp, '<span class="highlight">' + match + '</span>'));
    });
}
function highlightTitle($inputValue, $heading) {
    var rgxp = new RegExp($inputValue, 'gi');
    var repl = '<span class="highlight">' + $inputValue + '</span>';
    $heading.each(function (index, element) {
        $(this).html($(this).html().replace(rgxp, repl));
    });
}

if ($searchPage) {
    highlightText($inputValue, $text);
    highlightText($inputValue, $heading);
} 
