var $header = $('header');
var $navHeight = $('nav.menu').outerHeight();
var $logoHeight = $('#top-logo').outerHeight();
var headerHeight = $logoHeight + 16 + $navHeight;
var $adminBar = $('body.admin-bar');
var $bar;
if($adminBar.length >0 && $(window).width() > 1200) {
    $bar = 32;
}
else {
    $bar = 0;
}

if($header.length > 0) {
    var lastScrollTop = 0;
    $(window).scroll(function(e) {
        var scrollTop = $(this).scrollTop();

        if (scrollTop < headerHeight + $navHeight + $bar) {
            $header.addClass('sticky').removeClass('show');
            if (scrollTop < headerHeight){
                $header.removeClass('sticky show animate');
            }
        } else if (scrollTop >= headerHeight + $navHeight + $bar) {
            if (scrollTop < lastScrollTop) { // Scrolling up
                $header.addClass('sticky animate show')
            } else {
                $header.addClass('sticky').removeClass('show');
            }
        }

        lastScrollTop = scrollTop;
    });

    var $hero = $('#content-section .hero');
    var $heroSlideshow = $('#content-section .hero-slideshow');
    var $imageTextBox = $('#content-section section.image-text-box');
    var $consecutiveImageTextBoxes = [];
    $imageTextBox.each(function(index) {
        if ($imageTextBox.eq(index).prev().hasClass('image-text-box')) {
            $consecutiveImageTextBoxes.push($imageTextBox.eq(index));
        }
    });
    if ($consecutiveImageTextBoxes.length > 0) {
        $consecutiveImageTextBoxes[$consecutiveImageTextBoxes.length-1].addClass('more-padding');
    }

    if ($heroSlideshow.length > 0 || $hero.length > 0) {
        $('body').addClass('menu-absolute');
    }

    if ($hero.length > 0 && $hero.next().hasClass('image-text-box')) {
        $('body').addClass('has-hero');
        $('body').addClass('menu-absolute');
    }
    else if ($hero.length > 0 || $heroSlideshow.length > 0) {
        $('body').addClass('menu-absolute');
    }
    else {
        $('#content-section section.image-text-box').last().addClass('bottom-padding');
    }

    if ($(window).width() > 1643) {
        var $navOffset = $('header nav ul').offset().left;
        $('header #top-logo').css({
            'margin-left': $navOffset
        });
    }
    $(window).resize(function () {
        var $navOffset = $('header nav ul').offset().left;
        if ($(window).width() > 1643) {
            $('header #top-logo').css({
                'margin-left': $navOffset
            });
        }
        else if ($(window).width() > 1150 && $(window).width() <= 1643) {
            $('header #top-logo').css({
                'margin-left': 4*16
            });
        }
        else {
            $('header #top-logo').css({
                'margin-left': 0
            });
        }
    });
}