var navIcon = document.getElementById('nav-icon');
if (navIcon) {
    navIcon.addEventListener('click', function () {
        var nav = document.getElementsByTagName('nav')[0];

        if (navIcon.classList.contains('open')) {
            navIcon.classList.remove('open');
            nav.classList.remove('nav-mobile-show');
        } else {
            navIcon.classList.add('open');
            nav.classList.add('nav-mobile-show');
        }
    });
}