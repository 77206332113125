let $formBlock = $("section.questions-form");

if ($formBlock.length > 0) {
  let $form = $formBlock.find("form");
  let $answers = $form.find('input[type="checkbox"]');
  let $submitMessage = $form.find('.submit-message');
  $form.find('.wpcf7-form-control.wpcf7-quiz').css({ 'display': 'none' });

  $answers.click(function (e) {
    let $answer = $(this);
    let $selectedAnswer = $answer.attr("value");
    let $question = $answer.attr("name").replace("-ans", "");
    let $questionInput = $('input[name="' + $question + '"]');
    if ($answer[0].checked == false) {
      $questionInput.attr("value", "");
      $questionInput[0].value = '';
    } else {
      $questionInput.attr("value", $selectedAnswer);
      $questionInput[0].value = $selectedAnswer;
    }
  });

  document.addEventListener( 'wpcf7invalid', function( event ) {
    $form.find('input.wpcf7-quiz').each(function (index) {
        let $input = $(this);
        let $prevAnswer = $(this).attr("value");
        setTimeout(function() {
            if($prevAnswer != undefined) {
                $input.attr('value', $prevAnswer);
                $input[0].value = $prevAnswer;
            }
        },1000);
    });
  }, false );

  document.addEventListener( 'wpcf7submit', function( event ) {
    if($form.attr('data-status') != 'invalid') {
        $form.find('.wpcf7-response-output').css('display', 'none');
        setTimeout(function() {
            $form.find('.wpcf7-response-output').css('display', 'inline-block');
            $submitMessage.insertAfter($form.find('.wpcf7-response-output'));
            $submitMessage.css({'display': 'inline-block'});
        }, 1000);
    }
  }, false );

  document.addEventListener( 'wpcf7submit', function( event ) {
    $submitMessage.css({'display': 'none'});
  }, false );

}