var $links = $('.media-preview .filter a');
$('.media-preview .posts-wrapper').css({
    'display': 'none'
});
$('.media-preview .posts-wrapper.news-posts').css({
    'display': 'flex'
});

$links.click(function(e) {
    e.preventDefault();
    if(!$(this).hasClass('active')) {
        var $target = $(this).data('target');
        $('.media-preview .posts-wrapper').css({
            'display': 'none'
        });
        $('.media-preview .posts-wrapper.' + $target).css({
            'display': 'flex'
        });
        $links.removeClass('active');
        $(this).addClass('active');
    }
});