import './mobile-nav'
import './block-map'
import './block-media-preview'
import './hero-slideshow';
import './search';
import './header';
import './block-questions-form';

var $contentSection = $('#content-section');
var $firstChild = $contentSection.children('h1').first();
if($firstChild.length > 0) {
    $firstChild.addClass('margin-top');
}

var $articleImg = $('article figure.article-thumbnail img');
var $imageWidth = $articleImg.outerWidth(false);
$articleImg.parent().children('figcaption').css({
    'width': $imageWidth,
    'margin': '.5em auto 1em auto'
});

$(window).resize(function() {
    var $imageWidth = $articleImg.outerWidth(false);
    $articleImg.parent().children('figcaption').css({
        'width': $imageWidth,
        'margin': '.5em auto 1em auto'
    });
});